import React, { lazy, Suspense } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Background from "./components/Background";
import Footer from "./components/Footer";

// Lazy load components
const Experience = lazy(() =>
  import(/* webpackChunkName: "experience" */ "./components/Experience")
);
const Projects = lazy(() =>
  import(/* webpackChunkName: "projects" */ "./components/Projects")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "./components/Contact")
);

function App() {
  return (
    <div>
      <Background />
      <Header />
      <Hero />
      <About />
      <Suspense
        fallback={
          <div className="min-h-screen flex items-center justify-center">
            <div className="text-[#00F1FF]">Loading...</div>
          </div>
        }
      >
        <Experience />
        <Projects />
        <Contact />
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
