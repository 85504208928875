import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  // Use simpler animations for mobile
  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: {
      duration: window.innerWidth < 768 ? 0.3 : 0.5,
      // Reduce motion on mobile
      type: window.innerWidth < 768 ? "tween" : "spring",
    },
  };

  return (
    <section
      className="min-h-screen flex items-center relative px-4 sm:px-6 lg:px-8"
      aria-label="Introduction"
    >
      <div className="max-w-7xl mx-auto w-full">
        <div className="space-y-5 sm:space-y-8">
          <motion.span
            {...animationProps}
            className="text-[#00F1FF] font-mono text-sm sm:text-base"
          >
            Hi there, I'm
          </motion.span>

          <motion.h1
            {...animationProps}
            className="text-4xl sm:text-5xl md:text-7xl font-bold text-white leading-tight"
          >
            Hozefa Bharmal
          </motion.h1>

          <motion.h2
            {...animationProps}
            className="text-2xl sm:text-3xl md:text-4xl font-semibold text-[#B8A8BC]"
          >
            Full Stack Developer
          </motion.h2>

          <motion.p
            {...animationProps}
            className="max-w-lg text-sm sm:text-base text-[#B8A8BC]/80"
          >
            I'm a Full Stack Developer specializing in building modern web
            applications with React and Node.js. Focused on creating clean,
            efficient, and user-centric digital solutions that deliver
            exceptional experiences.
          </motion.p>

          <motion.div
            {...animationProps}
            className="flex flex-col sm:flex-row gap-4"
          >
            <motion.button
              onClick={() => scrollToSection("projects")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 bg-[#BD00FF] text-white rounded-lg font-medium 
                       hover:bg-[#00F1FF] transition-colors duration-300
                       focus:outline-none focus:ring-2 focus:ring-[#00F1FF] focus:ring-offset-2"
              aria-label="View my work"
            >
              View My Work
            </motion.button>

            <motion.button
              onClick={() => scrollToSection("contact")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 border-2 border-[#BD00FF] text-[#BD00FF] rounded-lg font-medium 
                       hover:bg-[#BD00FF]/10 transition-colors duration-300
                       focus:outline-none focus:ring-2 focus:ring-[#BD00FF] focus:ring-offset-2"
              aria-label="Contact me"
            >
              Contact Me
            </motion.button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
