import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="relative py-20"
      id="about"
      aria-labelledby="about-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            <h2 id="about-heading" className="text-3xl font-bold text-white">
              <span className="text-[#00F1FF]">01. </span>About Me
            </h2>

            <div className="space-y-4 text-[#B8A8BC]">
              <p>
                Hello! I'm Hozefa Bharmal, a Full Stack Developer passionate
                about creating modern web applications. With a strong foundation
                in Computer Science from DY Patil University, I combine
                technical expertise with creative problem-solving to build
                engaging digital experiences.
              </p>

              <p>
                I specialize in developing responsive and intuitive web
                applications with a focus on clean code architecture and optimal
                performance. My approach emphasizes modern development
                practices, ensuring that each project is built with scalability
                and maintainability in mind.
              </p>

              <p>
                Beyond coding, I'm deeply interested in user experience design
                and performance optimization. I stay current with emerging
                technologies and best practices to deliver solutions that meet
                today's web standards while being prepared for tomorrow's
                challenges.
              </p>

              <p>Core technologies in my professional stack include:</p>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
              className="grid grid-cols-2 gap-2 text-[#B8A8BC]"
            >
              {[
                "React & Next.js",
                "Node.js & Express",
                "MongoDB & SQL",
                "REST APIs",
                "Performance Optimization",
                "Responsive Design",
                "Git & Version Control",
                "Web Accessibility",
              ].map((skill, index) => (
                <div
                  key={skill}
                  className="flex items-center space-x-2"
                  role="listitem"
                >
                  <span className="text-[#00F1FF]" aria-hidden="true">
                    ▹
                  </span>
                  <span>{skill}</span>
                </div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
